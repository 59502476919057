import AuthLayout from 'layouts/AuthLayouts';
import RouteProtect from 'layouts/RouteProtect';

import {
  useAuth,
  useUniversity,
  useCourse,
  useBank,
  // usePricing,
  // useBootCamps,
  // useMentoring,
  useOrientation,
  useSectors,
  useDepartments,
  useCities,
  usePrograms,
  useModalitys,
  useKnowledgeArea,
  useBasicCores,
  useLevelsEducation,
  useLevels,
  usePeriods,
  useBankRange,
  useBankFeatures,
  useBankRequirements,
  useMarksCourses,
  // useMarksMentoring,
  // useMarksBootCamps,
  useScholarship,
  useRoles,
  useCampus,
  useAgreements,
  useWelfare,
  useBonds,
  useRectors,
  useImages,
  // useAgreementsProgram,
  useCharges,
  useSpreads,
  // useTeachers,
  useRequirements,
  useUniversityProfile,
  useDimensions
} from 'hooks';
import Access from 'routes/Access';
// import useScholarship from 'trash/useScolarship';

import Auth from 'pages/Auth';
import ForgotPassword from 'pages/ForgotPassword';
import Default from 'pages/Default';
import Dashboard from 'pages/dashboard/Default';
import DashboardELearning from 'pages/dashboard/e-learning';

import _404 from 'pages/_404';

import Leads from 'pages/dashboard/Leads';
import LeadsClient from 'pages/dashboard/Leads_Client';
import Banck from 'pages/dashboard/Banck';

import Settings from 'pages/settings/Settings';
import Referral from 'pages/dashboard/Referral';
import ReferralList from 'pages/ReferralList';
import Survey from 'pages/dashboard/Survey';
import SurveyList from 'pages/survey/SurveyList';
import SurveyQuestion from 'pages/survey/SurveyQuestion';
import Scholarship from 'pages/Scholarship';
import Applications from 'pages/Applications';
import Roles from 'pages/Roles';
import University from 'pages/University';
import Program from 'pages/Program';
import Gallery from 'pages/Gallery';
import UniversityProfile from 'pages/UniversityProfile';
import DashboardUniversity from 'pages/dashboard/University';
import IES from 'pages/dashboard/IES';
import Pricing from 'pages/dashboard/Pricing';
import Accredited from 'pages/dashboard/Accredited';
import Programs from 'pages/dashboard/Programs';
import NPS from 'pages/dashboard/NPS';
import Benchmark from 'pages/dashboard/Benchmark';
import NPS_Admin from 'pages/dashboard/NPS_Admin';

const routesMap = () => {
  const access = Access();

  return [
    {
      path: '/',
      component: AuthLayout,
      children: [
        { path: '/', component: Auth, exact: true },
        {
          path: 'forgot-password',
          component: ForgotPassword,
          props: { prop: 'email', pt: false }
        },
        {
          path: 'reset-password',
          component: ForgotPassword,
          props: { prop: 'rewrite', pt: true }
        }
      ]
    },
    // dashboards
    {
      path: '/dashboard',
      component: RouteProtect,
      children: [
        { path: '', component: Dashboard, exact: true },
        {
          path: 'referrals',
          component: Referral,
          exact: true,
          props: { authorized: access.referralsDash }
        },
        {
          path: 'survey',
          component: Survey,
          exact: true,
          props: { authorized: access.surveyDash }
        },
        {
          path: 'university',
          component: DashboardUniversity,
          exact: true,
          props: { authorized: access.universityDash }
        },
        {
          path: 'e-learning',
          component: DashboardELearning,
          exact: true,
          props: { authorized: access.elearningDash }
        },
        {
          path: 'leads',
          component: Leads,
          exact: true,
          props: { authorized: access.LeadsDash }
        },
        {
          path: 'leads_client',
          component: LeadsClient,
          exact: true,
          props: { authorized: access.leadsUniversityDash }
        },
        {
          path: 'banck',
          component: Banck,
          exact: true,
          props: { authorized: access.BanckDash }
        },
        {
          path: 'ies-colombia',
          component: IES,
          exact: true,
          props: { authorized: access.iesDash }
        },
        {
          path: 'pricing',
          component: Pricing,
          exact: true,
          props: { authorized: access.iesPricing }
        },
        {
          path: 'accredited',
          component: Accredited,
          exact: true,
          props: { authorized: access.iesAccredited }
        },
        {
          path: 'programs',
          component: Programs,
          exact: true,
          props: { authorized: access.iesPrograms }
        },
        {
          path: 'nps',
          component: NPS,
          exact: true,
          props: { authorized: access.NPSDash }
        },
        {
          path: 'nps_super',
          component: NPS_Admin,
          exact: true,
          props: { authorized: access.NPSSuper }
        },
        {
          path: 'benchmark',
          component: Benchmark,
          exact: true,
          props: { authorized: access.BenchmarkDash }
        }
      ]
    },
    // referrals
    {
      path: '/referral',
      component: RouteProtect,
      children: [
        {
          path: 'referral-list',
          component: ReferralList,
          exact: true,
          props: { authorized: access.referralList }
        }
      ]
    },
    // security
    {
      path: '/user',
      component: RouteProtect,
      children: [
        {
          path: 'settings',
          component: Settings,
          exact: true
        }
      ]
    },
    {
      path: '/security',
      component: RouteProtect,
      children: [
        {
          path: '',
          component: Default,
          exact: true,
          props: { useHook: useAuth, authorized: access.user }
        }
      ]
    },
    // NPS
    {
      path: '/survey',
      component: RouteProtect,
      children: [
        {
          path: 'survey-list',
          component: SurveyList,
          exact: true,
          props: { authorized: access.surveyListNPS }
        },
        {
          path: 'survey-question',
          component: SurveyQuestion,
          exact: true,
          props: { authorized: access.surveyQuestionNPS }
        }
        // {
        //   path: 'survey-referral',
        //   component: ReferralSurvey,
        //   exact: true,
        //   props: { authorized: access.surveyList }
        // }
      ]
    },
    // CMS ALL
    {
      path: '/cms',
      component: RouteProtect,
      children: [
        // CMS Futura
        {
          path: 'universities',
          component: Default,
          exact: true,
          props: { useHook: useUniversity, authorized: access.universitiesCMSF }
        },
        {
          path: 'sectors',
          component: Default,
          exact: true,
          props: { useHook: useSectors, authorized: access.sectorsCMSF }
        },
        {
          path: 'departments',
          component: Default,
          exact: true,
          props: { useHook: useDepartments, authorized: access.departmentsCMSF }
        },
        {
          path: 'cities',
          component: Default,
          exact: true,
          props: { useHook: useCities, authorized: access.citiesCMSF }
        },
        {
          path: 'modalitys',
          component: Default,
          exact: true,
          props: { useHook: useModalitys, authorized: access.modalitysCMSF }
        },
        {
          path: 'knowledge-areas',
          component: Default,
          exact: true,
          props: {
            useHook: useKnowledgeArea,
            authorized: access.knowledge_areaCMSF
          }
        },
        {
          path: 'basic-core',
          component: Default,
          exact: true,
          props: { useHook: useBasicCores, authorized: access.basic_coreCMSF }
        },
        {
          path: 'levels-education',
          component: Default,
          exact: true,
          props: {
            useHook: useLevelsEducation,
            authorized: access.levels_educationCMSF
          }
        },
        {
          path: 'levels',
          component: Default,
          exact: true,
          props: { useHook: useLevels, authorized: access.levelsCMSF }
        },
        {
          path: 'periods',
          component: Default,
          exact: true,
          props: { useHook: usePeriods, authorized: access.periodsCMSF }
        },
        {
          path: 'marksCourses',
          component: Default,
          exact: true,
          props: {
            useHook: useMarksCourses,
            authorized: access.marksCoursesCMSF
          }
        },
        {
          path: 'dimensions',
          component: Default,
          exact: true,
          props: {
            useHook: useDimensions,
            authorized: access.dimensionCMSF
          }
        },
        // CMS
        // University
        {
          path: 'university',
          component: UniversityProfile,
          exact: true,
          props: {
            useHook: useUniversityProfile,
            authorized: access.universityCMSR
          }
        },
        {
          path: 'university/campus',
          component: University,
          exact: true,
          props: {
            route: 'campus',
            authorized: access.universityAll,
            useHook: useCampus
          }
        },
        {
          path: 'university/agreements',
          component: University,
          exact: true,
          props: {
            route: 'agreements',
            authorized: access.universityAll,
            useHook: useAgreements
          }
        },
        {
          path: 'university/welfare',
          component: University,
          exact: true,
          props: {
            route: 'welfare',
            authorized: access.universityAll,
            useHook: useWelfare
          }
        },
        {
          path: 'university/bonds',
          component: University,
          exact: true,
          props: {
            route: 'bonds',
            authorized: access.universityAll,
            useHook: useBonds
          }
        },
        {
          path: 'university/requirements',
          component: University,
          exact: true,
          props: {
            route: 'requirements',
            authorized: access.universityAll,
            useHook: useRequirements
          }
        },
        {
          path: 'university/rectors',
          component: University,
          exact: true,
          props: {
            route: 'rectors',
            authorized: access.universityAll,
            useHook: useRectors
          }
        },
        {
          path: 'university/images',
          component: Gallery,
          exact: true,
          props: {
            route: 'images',
            authorized: access.universityAll,
            useHook: useImages
          }
        },
        // Programs
        {
          path: 'programs',
          component: Default,
          exact: true,
          props: { useHook: usePrograms, authorized: access.programs_CMSR }
        },
        {
          path: 'scholarship-program',
          component: Scholarship,
          exact: true,
          props: {
            route: 'program',
            authorized: access.programs_CMSR,
            useHook: useScholarship
          }
        },
        {
          path: 'role-program',
          component: Roles,
          exact: true,
          props: {
            route: 'program',
            authorized: access.programs_CMSR,
            useHook: useRoles
          }
        },
        {
          path: 'programs/charges',
          component: Program,
          exact: true,
          props: {
            route: 'charges',
            authorized: access.programs_CMSR,
            useHook: useCharges
          }
        },
        {
          path: 'programs/spreads',
          component: Program,
          exact: true,
          props: {
            route: 'spreads',
            authorized: access.programs_CMSR,
            useHook: useSpreads
          }
        },
        // E Learning
        {
          path: 'courses',
          component: Default,
          exact: true,
          props: { useHook: useCourse, authorized: access.courseCMSR }
        },
        {
          path: 'scholarship-course',
          component: Scholarship,
          exact: true,
          props: {
            route: 'course',
            authorized: access.courseCMSR,
            useHook: useScholarship
          }
        },
        {
          path: 'role-course',
          component: Roles,
          exact: true,
          props: {
            route: 'course',
            authorized: access.courseCMSR,
            useHook: useRoles
          }
        },

        // {
        //   path: 'programs/agreements',
        //   component: Program,
        //   exact: true,
        //   props: {
        //     route: 'agreements',
        //     authorized: access.programsCMS,
        //     useHook: useAgreementsProgram
        //   }
        // },

        // bank
        {
          path: 'banksranges',
          component: Default,
          exact: true,
          props: { useHook: useBankRange, authorized: access.rangeCMSR }
        },
        {
          path: 'bank',
          component: Default,
          exact: true,
          props: { useHook: useBank, authorized: access.bankCMSR }
        },
        {
          path: 'banksfeatures',
          component: Default,
          exact: true,
          props: {
            useHook: useBankFeatures,
            authorized: access.banksfeaturesCMSR
          }
        },
        {
          path: 'banksrequirements',
          component: Default,
          exact: true,
          props: {
            useHook: useBankRequirements,
            authorized: access.banksrequirementsCMSR
          }
        },
        // orientation
        {
          path: 'orientation',
          component: Default,
          exact: true,
          props: { useHook: useOrientation, authorized: access.orientationCMSR }
        },
        // scolarships
        {
          path: 'applications-course',
          component: Applications,
          exact: true,
          props: { route: 'course', authorized: access.scolarshipCourseCMS }
        },
        {
          path: 'applications-program',
          component: Applications,
          exact: true,
          props: { route: 'program', authorized: access.scolarshipProgramCMS }
        }

        // {
        //   path: 'mentorings',
        //   component: Default,
        //   exact: true,
        //   props: {
        //     useHook: useMentoring,
        //     authorized: access.mentoringCMS
        //   }
        // },
        // {
        //   path: 'scholarship-mentoring',
        //   component: Scholarship,
        //   exact: true,
        //   props: {
        //     route: 'mentoring',
        //     authorized: access.scolarshipMentoringCMS,
        //     useHook: useScholarship
        //   }
        // },
        // {
        //   path: 'marksMentoring',
        //   component: Default,
        //   exact: true,
        //   props: {
        //     useHook: useMarksMentoring,
        //     authorized: access.marksMentoringCMS
        //   }
        // },
        // {
        //   path: 'bootcamps',
        //   component: Default,
        //   exact: true,
        //   props: { useHook: useBootCamps, authorized: access.bootcampsCMS }
        // },
        // {
        //   path: 'programs/teachers',
        //   component: Program,
        //   exact: true,
        //   props: {
        //     route: 'teachers',
        //     authorized: access.programsCMS,
        //     useHook: useTeachers
        //   }
        // }
        // {
        //   path: 'marksBootCamps',
        //   component: Default,
        //   exact: true,
        //   props: {
        //     useHook: useMarksBootCamps,
        //     authorized: access.marksBootCampsCMS
        //   }
        // },

        // {
        //   path: 'applications-mentoring',
        //   component: Applications,
        //   exact: true,
        //   props: {
        //     route: 'mentoring',
        //     authorized: access.scolarshipMentoringCMS
        //   }
        // },

        // {
        //   path: 'applications-bootcamp',
        //   component: Applications,
        //   exact: true,
        //   props: {
        //     route: 'bootcamp',
        //     authorized: access.scolarshipBootCampsCMS
        //   }
        // },

        // {
        //   path: 'scholarship-bootcamp',
        //   component: Scholarship,
        //   exact: true,
        //   props: {
        //     route: 'bootcamp',
        //     authorized: access.scolarshipBootCampsCMS,
        //     useHook: useScholarship
        //   }
        // },

        // {
        //   path: 'role-mentoring',
        //   component: Roles,
        //   exact: true,
        //   props: {
        //     route: 'mentoring',
        //     authorized: access.scolarshipMentoringCMS,
        //     useHook: useRoles
        //   }
        // },

        // {
        //   path: 'role-bootcamp',
        //   component: Roles,
        //   exact: true,
        //   props: {
        //     route: 'bootcamp',
        //     authorized: access.scolarshipBootCampsCMS,
        //     useHook: useRoles
        //   }
        // },
      ]
    },
    { path: '*', component: _404 }
  ];
};

export default routesMap;
