import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SimpleBarReact from 'simplebar-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Form, Image, Card } from 'react-bootstrap';
// import { courseFilters } from 'data/elearning/courseData';
import { slugifyText } from 'helpers/utils';
import Flex from 'components/common/Flex';
import SubtleBadge from 'components/common/SubtleBadge';
import { useDefault } from 'hooks';

// export const courseFilters = [];
export const CourseFiltersNPS = ({
  setShow,
  isOffcanvas,
  data,
  setFilter,
  dimension,
  setNumDimension,
  setSearch
}) => {
  const { filter } = useDefault();
  const [filterOptions, setFilterOptions] = useState([]);
  const [availabled, setAvailabled] = useState(false);
  const [courseFilters, setCourseFilters] = useState([
    {
      label: 'Modalidad',
      options: []
    },
    {
      label: 'Nivel Académico',
      options: []
    }
    // {
    //   label: 'Programas',
    //   options: []
    // }
  ]);
  useEffect(() => {
    setCourseFilters([
      {
        label: 'Modalidad',
        options: data.modalitys
      },
      {
        label: 'Nivel Académico',
        options: data.levels
      }
      // {
      //   label: 'Programas',
      //   options: data.programs
      // }
    ]);
  }, []);

  const handleFilterOptions = e => {
    const { type, name, value, checked } = e.target;
    if (type === 'checkbox') {
      let options = [...filterOptions];
      options = options.filter(option => option.value !== value);

      let existingModality = options.find(option => option.name === 'modality');
      let existingLevels = options.find(option => option.name === 'levels');

      if (name === 'modality' && existingModality) {
        if (checked) {
          existingModality.value = value;
        }
      } else if (name === 'levels' && existingLevels) {
        if (checked) {
          existingLevels.value = value;
        }
      } else {
        if (checked) {
          options.push({ name, value });
        }
      }
      existingModality = options.find(option => option.name === 'modality');
      existingLevels = options.find(option => option.name === 'levels');
      if (existingLevels && existingModality) {
        setAvailabled(true);
      }
      setFilterOptions(options);
    }
  };
  // useEffect(() => {
  //   filterOptions.forEach(element => {
  //     // console.log(element);
  //   });
  // }, [filterOptions]);

  const handleClick = () => {
    setSearch(true);
    filter('dashboard_nps/cal_prom_answers_university', filterOptions).then(
      res => {
        setFilter(false);
        setSearch(false);
        setNumDimension(res.dimensions);
      }
    );
  };
  const handleClickDelete = () => {
    setFilterOptions([]);
    setFilter(true);
    setNumDimension(dimension);
    setAvailabled(false);
  };

  return (
    <Card className="course-filter">
      <SimpleBarReact style={{ height: '100%' }}>
        <Card.Header as={Flex} className="flex-between-center pt-x1">
          <Flex className="gap-2 flex-xl-grow-1 align-items-center justify-content-xl-between">
            <h5 className="mb-0 text-700 fs-9 d-flex align-items-center">
              <FontAwesomeIcon icon="filter" className="fs-10 me-1" />
              <span>Filtro</span>
            </h5>
            <Button
              variant="outline-secondary"
              size="sm"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => handleClickDelete()}
            >
              <FontAwesomeIcon icon="redo-alt" className="me-1 fs-11" />
              Reiniciar
            </Button>
            <Button
              size="sm"
              disabled={!availabled}
              variant="primary"
              className="ms-2 mt-0 mb-0"
              style={{ fontSize: '12px' }}
              onClick={() => handleClick()}
            >
              <FontAwesomeIcon icon="check" className="me-1 fs-11" />
              Aceptar
            </Button>
          </Flex>
          {isOffcanvas && (
            <Button
              onClick={() => setShow(false)}
              className="btn-close text-reset"
              size="sm"
              variant="link"
            ></Button>
          )}
        </Card.Header>
        <Card.Body className="py-0">
          {filterOptions.length > 0 && (
            <Flex wrap="wrap" className="gap-2 mb-3">
              {filterOptions.map(tag => (
                <>
                  <div
                    style={{
                      width: '100%',
                      overflow: 'auto',
                      whiteSpace: 'normal'
                    }}
                  >
                    <SubtleBadge
                      key={tag.value}
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-capitalize bg-300 text-700 py-0 text-400'
                          : 'text-capitalize bg-300 text-700 py-0 text-700'
                      }`}
                    >
                      {tag.value}
                      <Button
                        size="sm"
                        variant="link"
                        className="p-0 text-700"
                        onClick={() =>
                          setFilterOptions(
                            filterOptions.filter(
                              ({ value }) => value !== tag.value
                            )
                          )
                        }
                      >
                        <FontAwesomeIcon icon="times" className="ms-1 fs-11" />
                      </Button>
                    </SubtleBadge>
                  </div>
                </>
              ))}
            </Flex>
          )}
          <ul className="list-unstyled">
            {courseFilters.map((filter, index) => (
              <FilterItem
                key={slugifyText(filter.label)}
                index={index}
                courseFilters={courseFilters}
                filter={filter}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                handleFilterOptions={handleFilterOptions}
              />
            ))}
          </ul>
        </Card.Body>
      </SimpleBarReact>
    </Card>
  );
};

CourseFiltersNPS.propTypes = {
  setShow: PropTypes.func,
  isOffcanvas: PropTypes.bool,
  data: PropTypes.object.isRequired,
  dimension: PropTypes.object.isRequired,
  setFilter: PropTypes.func,
  setNumDimension: PropTypes.func,
  setSearch: PropTypes.func
  // setUniversities: PropTypes.func
};

const FilterItem = ({
  filter,
  courseFilters,
  index,
  filterOptions,
  handleFilterOptions
}) => {
  const [open, setOpen] = useState(false);

  return (
    <li className={`${courseFilters.length - 1 !== index && 'border-bottom'}`}>
      <Button
        variant="link"
        onClick={() => setOpen(!open)}
        aria-controls={`${slugifyText(filter.label)}-collapse`}
        aria-expanded={index === 4 ? !open : open}
        className="collapse-indicator-plus w-100 fs-11 fw-medium text-start text-600 text-decoration-none py-3 px-0"
      >
        {filter.label}
      </Button>
      <Collapse
        in={index === 4 ? !open : open}
        id={`${slugifyText(filter.label)}-collapse`}
      >
        <ul className="list-unstyled">
          {filter.options &&
            filter.options.map(option => (
              <li key={slugifyText(option.label)}>
                <Form.Check
                  type={option.type}
                  className="form-check d-flex ps-0"
                >
                  <Form.Check.Label
                    className="fs-10 flex-1 text-truncate"
                    htmlFor={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                  >
                    {option.icon && (
                      <FontAwesomeIcon
                        icon={option.icon}
                        className={`me-3 ${
                          option.iconShrink ? 'fs-11' : 'fs-10'
                        }`}
                      />
                    )}
                    {option.svg && (
                      <Image
                        src={option.svg}
                        width={13}
                        alt=""
                        className="me-3"
                      />
                    )}
                    <div
                      style={{
                        width: '90%',
                        overflow: 'auto',
                        whiteSpace: 'normal'
                      }}
                      className={`${
                        localStorage.getItem('isDark') === 'true'
                          ? 'text-400'
                          : 'text-700'
                      }`}
                    >
                      {option.label}
                    </div>
                  </Form.Check.Label>

                  <Form.Check.Input
                    type={option.type}
                    onChange={e => handleFilterOptions(e)}
                    checked={
                      option.type === 'checkbox'
                        ? filterOptions.some(
                            ({ value }) => option.value === value
                          )
                        : undefined
                    }
                    id={`filter-${slugifyText(filter.label)}-${slugifyText(
                      option.label
                    )}`}
                    name={option.name}
                    value={option.value}
                  />
                </Form.Check>
              </li>
            ))}
        </ul>
      </Collapse>
    </li>
  );
};

FilterItem.propTypes = {
  index: PropTypes.number,
  courseFilters: PropTypes.array,
  filter: PropTypes.shape({
    label: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        name: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      })
    )
  }),
  handleFilterOptions: PropTypes.func,
  filterOptions: PropTypes.array
};

export default CourseFiltersNPS;
