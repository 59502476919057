import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import FalconCardHeader from 'components/common/FalconCardHeader';
import BarSubComponent from './Bar2SubComponent';
import Flex from 'components/common/Flex';

const Bar = ({ data }) => {
  const { bar, dataDimen } = data;
  console.log(data);
  return (
    <Card>
      <FalconCardHeader
        title={`Dimensión: ${dataDimen?.name}`}
        titleTag="h6"
        className="border-200 border-bottom py-3"
        // endEl={<CardDropdown />}
      />
      <Card.Body dir="ltr">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="rtl-row-reverse"
        >
          <h6 className="fs--2 text-500 flex-1">Factor</h6>
          <h6 className="fs--2 text-500 mx-2">
            % &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;
          </h6>
        </Flex>
        <BarSubComponent data={bar} />
      </Card.Body>
    </Card>
  );
};

Bar.propTypes = {
  data: PropTypes.object
};

export default Bar;
