import { useAuth } from 'hooks';

const Access = () => {
  const { auth = {} } = useAuth() || {};

  const role = auth?.user_role || auth.role || {};
  const email = auth?.user_email || auth.email || {};
  // const university = auth?.user_university || auth.university_profile || {};
  const client = auth?.user_client || auth.client || {};
  const isSuper = role === 'super' || email === 'master';
  const isAdmin = role === 'admin';
  const isSuperOrAdmin = isSuper || isAdmin;
  const isClientEmpty = !Object.keys(client).length;
  // const isUniversityNotEmpty =
  //   !!Object.keys(university).length || email === 'master';
  const isClientUniversidad = client === 'Universidad';
  const isClientELearning = client === 'E-learning';
  const isClientBanco = client === 'Banco';
  const isClientOrientacion = client === 'Orientación';

  return {
    // dashboard
    dashboard: isSuperOrAdmin,
    defaultDash: isSuperOrAdmin,
    referralsDash: isSuperOrAdmin && isClientEmpty,
    surveyDash: isSuperOrAdmin && isClientEmpty,
    universityDash: isAdmin && !isClientEmpty && isClientUniversidad,
    leadsUniversityDash: isAdmin && !isClientEmpty && isClientUniversidad,
    elearningDash: isSuper || (isAdmin && !isClientEmpty && isClientELearning),
    LeadsDash: isSuper,
    BanckDash: isSuper,
    iesDash: isSuperOrAdmin,
    iesAccredited: isSuperOrAdmin,
    iesPricing: isSuperOrAdmin,
    iesPrograms: isSuperOrAdmin,
    NPSDash: isAdmin && !isClientEmpty && isClientUniversidad,
    NPSSuper: isSuper,
    BenchmarkDash:
      isSuper || (isAdmin && !isClientEmpty && isClientUniversidad),

    // CMS Futura
    futuraCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    universitiesCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    universitiesCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    sectorsCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    departmentsCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    citiesCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),

    programsCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    modalitysCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    knowledge_areaCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    basic_coreCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    levelsCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    levels_academicCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    periodsCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),

    modalitysCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    knowledge_areaCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    basic_coreCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    levels_educationCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    // levels_academicCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),
    periodsCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    // marksCoursesCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    // marksMentoringCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    // marksBootCampsCMS: isSuper || (isSuperOrAdmin && isClientEmpty),

    courseCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    marksCoursesCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),

    npsCMS: isSuper || (isSuperOrAdmin && isClientEmpty),
    dimensionCMSF: isSuper || (isSuperOrAdmin && isClientEmpty),

    // CMS
    programs_CMS: isSuper || (isAdmin && !isClientEmpty && isClientUniversidad),
    programs_CMSR:
      isSuper || (isAdmin && !isClientEmpty && isClientUniversidad),
    course_CMS:
      isSuper ||
      (isAdmin && !isClientEmpty && (isClientUniversidad || isClientELearning)),
    courseCMSR:
      isSuper ||
      (isAdmin && !isClientEmpty && (isClientUniversidad || isClientELearning)),
    universityAll:
      isSuper || (isAdmin && !isClientEmpty && isClientUniversidad),
    universityCMSR: isAdmin && !isClientEmpty && isClientUniversidad,

    // bank
    bankCMS: isSuper || (isAdmin && isClientBanco),
    bankCMSR: isSuper || (isAdmin && isClientBanco),
    rangeCMSR: isSuper || (isAdmin && isClientBanco),
    banksfeaturesCMSR: isSuper || (isAdmin && isClientBanco),
    banksrequirementsCMSR: isSuper || (isAdmin && isClientBanco),

    // orientation
    orientationCMSR: isSuper || (isAdmin && isClientOrientacion),

    // course
    scolarshipCMS: isSuperOrAdmin,
    scolarshipProgramCMS:
      isSuper || (isAdmin && !isClientEmpty && isClientUniversidad),
    scolarshipCourseCMS: isSuperOrAdmin,
    // mentoringCMS: isSuperOrAdmin,
    // scolarshipMentoringCMS: isSuperOrAdmin,
    // scolarshipBootCampsCMS: isSuperOrAdmin,

    // referrals
    referralList: isSuper,

    // survey
    surveyListNPS: isSuper,
    surveyQuestionNPS: isSuper,

    // security
    user: isSuper
  };
};

export default Access;
