import ReactEChartsCore from 'echarts-for-react/lib/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { getColor, rgbaColor } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  LegendComponent
]);

const tooltipFormatter = params => {
  return params
    .map(
      ({ seriesName, value, borderColor }) =>
        `<div class="dot me-1 fs--2 d-inline-block" style="background-color: ${borderColor}"></div>              <span class='text-600'>${seriesName}: ${value}</span>`
    )
    .join('<br/>');
};

const splitLabel = (label, maxLength) => {
  let words = label.split(' ');
  let lines = [];
  let currentLine = '';

  words.forEach(word => {
    if ((currentLine + word).length <= maxLength) {
      currentLine += `${word} `;
    } else {
      lines.push(currentLine.trim());
      currentLine = `${word} `;
    }
  });

  lines.push(currentLine.trim());
  return lines.join('\n');
};

const getOptions = (dataUniv, nameFactor, nameUniversity, colorClasses) => ({
  color: getColor('gray-100'),
  tooltip: {
    trigger: 'axis',
    padding: [7, 10],
    backgroundColor: getColor('gray-100'),
    borderColor: getColor('gray-100'),
    textStyle: { color: getColor('dark') },
    borderWidth: 1,
    formatter: tooltipFormatter,
    transitionDuration: 0
  },
  legend: {
    data: nameUniversity,
    show: false // Deja esto como false ya que el componente padre controla la leyenda
  },
  xAxis: {
    type: 'category',
    data: nameFactor,
    boundaryGap: false,
    axisPointer: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    splitLine: { show: false },
    axisLine: {
      lineStyle: {
        color: rgbaColor('#000', 0.01),
        type: 'dashed'
      }
    },
    axisTick: { show: false },
    axisLabel: {
      color: getColor('gray-400'),
      margin: 15,
      formatter: value => splitLabel(value, 12),
      rich: {
        text: {
          lineHeight: 20
        }
      }
    }
  },
  yAxis: {
    type: 'value',
    axisPointer: { show: false },
    splitLine: {
      lineStyle: {
        color: getColor('gray-300'),
        type: 'dashed'
      }
    },
    boundaryGap: false,
    axisLabel: {
      show: true,
      color: getColor('gray-400'),
      margin: 15
    },
    axisTick: { show: false },
    axisLine: { show: false }
  },
  series: Object.keys(dataUniv).map((key, index) => ({
    name: nameUniversity[index],
    type: 'line',
    data: dataUniv[key],
    lineStyle: { color: colorClasses[index % colorClasses.length] },
    itemStyle: {
      borderColor: colorClasses[index % colorClasses.length],
      borderWidth: 2,
      backgroundColor: 'transparent'
    },
    symbol: 'circle',
    symbolSize: 10,
    emphasis: {
      scale: true
    },
    areaStyle: {
      color: {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: rgbaColor(colorClasses[index % colorClasses.length], 0.2)
          },
          {
            offset: 1,
            color: rgbaColor(colorClasses[index % colorClasses.length], 0)
          }
        ]
      }
    }
  })),
  grid: {
    right: '40px',
    left: '30px',
    bottom: '15%',
    top: '2%',
    containLabel: true
  }
});

const LineSubComponent = forwardRef(({ data }, ref) => {
  const { dataUniv, nameFactor, nameUniversity } = data;
  const colorClasses = [
    getColor('primary'),
    getColor('secondary'),
    getColor('success'),
    getColor('danger'),
    getColor('warning')
  ];
  return (
    <ReactEChartsCore
      ref={ref}
      echarts={echarts}
      option={getOptions(dataUniv, nameFactor, nameUniversity, colorClasses)}
    />
  );
});

LineSubComponent.propTypes = {
  data: PropTypes.object.isRequired
};

export default LineSubComponent;
