import React, { useState } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';

import LmsStats from 'components/dashboards/lms-stats/LmsStats';

import { useEffectAsync } from 'helpers/utils';
import { useDefault } from 'hooks';
import ProjectStatistics from 'components/dashboards/project-management/project-statistics/ProjectStatistics';
// import UniversityProjects from 'components/dashboards/default/UniversityProjects';
import BackDrop from 'components/templates/BackDrop';
import UnsolvedTickets from 'components/dashboards/unsolved-tickets/UnsolvedTickets';
import Avatar from 'components/common/Avatar';
import Flex from 'components/common/Flex';
// import UniversityProjects from 'components/dashboards/default/UniversityProjects';
import TableModal from 'components/dashboards/futura/TableModal';
import { EModal } from 'components/templates/Modal';

export const lmsStatistics = [
  {
    id: 0,
    title: 'Total Programas',
    amount: 4968,
    icon: 'user-graduate',
    color: 'primary',
    className:
      'border-md-end border-bottom border-xxl-bottom-0 pb-3 p-xxl-0 ps-md-0'
  },
  {
    id: 1,
    title: 'Pregrado',
    amount: 324,
    icon: 'book-open',
    color: 'info',
    className:
      'border-xxl-end border-bottom border-xxl-0 pb-3 pt-4 pt-md-0 pe-md-0 p-xxl-0'
  },
  {
    id: 2,
    title: 'Posgrado',
    amount: 3712,
    icon: 'book-open',
    color: 'success',
    className:
      'border-md-end border-bottom border-md-bottom-0 pb-3 pt-4 p-xxl-0 pb-md-0 ps-md-0'
  },
  {
    id: 3,
    title: 'Cursos',
    amount: 1054,
    icon: 'book',
    color: 'warning',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  },
  {
    id: 4,
    title: 'Presencial',
    amount: 1054,
    icon: 'chalkboard-teacher',
    color: 'dark',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  },
  {
    id: 5,
    title: 'Virtual',
    amount: 1054,
    icon: 'chalkboard-teacher',
    color: 'dark',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  },
  {
    id: 6,
    title: 'Distancia',
    amount: 1054,
    icon: 'chalkboard-teacher',
    color: 'dark',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  },
  {
    id: 7,
    title: '',
    amount: 'Si',
    icon: 'home',
    color: 'danger',
    className: 'pt-4 p-xxl-0 pb-0 pe-md-0'
  }
];

const DashboardUniversity = () => {
  // const columns2 = [
  //   {
  //     accessor: 'name',
  //     Header: 'Nombre',
  //     headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
  //     cellProps: {
  //       className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
  //     },
  //     Cell: rowData => {
  //       const { name, avatar } = rowData.row.original;
  //       return (
  //         <Flex alignItems="center" className="position-relative py-1">
  //           {avatar.img ? (
  //             <Avatar src={avatar.img} size="xl" className="me-2" />
  //           ) : (
  //             <Avatar size="xl" name={avatar.name} className="me-2" />
  //           )}
  //           <h6 className="mb-0">{name}</h6>
  //         </Flex>
  //       );
  //     }
  //   },
  //   {
  //     accessor: 'subject',
  //     Header: 'SNIES',
  //     headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
  //     cellProps: {
  //       className: 'py-2 pe-4'
  //     }
  //   },
  //   {
  //     accessor: 'price',
  //     Header: 'Precio',
  //     headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
  //     cellProps: {
  //       className: 'py-2 pe-4'
  //     },
  //     Cell: rowData => {
  //       const { price } = rowData.row.original;
  //       return (
  //         <Flex alignItems="center" className="position-relative py-1">
  //           <h6 className="mb-0">${price}</h6>
  //         </Flex>
  //       );
  //     }
  //   },
  //   {
  //     accessor: 'city',
  //     Header: 'Ciudad',
  //     headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
  //     cellProps: {
  //       className: 'py-2 pe-4'
  //     }
  //   }
  // ];
  const columns3 = [
    {
      accessor: 'name',
      Header: 'Nombre',
      headerProps: { className: 'ps-2 text-900', style: { height: '46px' } },
      cellProps: {
        className: 'py-2 white-space-nowrap pe-3 pe-xxl-4 ps-2'
      },
      Cell: rowData => {
        const { name, avatar } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            {avatar.img ? (
              <Avatar src={avatar.img} size="xl" className="me-2" />
            ) : (
              <Avatar size="xl" name={avatar.name} className="me-2" />
            )}
            <h6 className="mb-0">{name}</h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'subject',
      Header: 'SNIES',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'price',
      Header: 'Precio',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { price } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1">
            <h6 className="mb-0">${price}</h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'modality',
      Header: 'Modalidad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'level',
      Header: 'Nivel Académico',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    },
    {
      accessor: 'city',
      Header: 'Ciudad',
      headerProps: { style: { minWidth: '14.625rem' }, className: 'text-900' },
      cellProps: {
        className: 'py-2 pe-4'
      }
    }
  ];
  const [accreditedPrograms, setAccreditedPrograms] = useState();
  const [careersDisappear, setCareersDisappear] = useState({});
  const [top10, setTop10] = useState({});
  const [cities, setCities] = useState([]);
  const [levels, setLevels] = useState([]);
  const [modalitys, setModalitys] = useState([]);
  const [programs, setPrograms] = useState();
  const [name, setName] = useState(null);

  const [numCities, setNumCities] = useState([]);
  const [numUniversities, setNumUniversities] = useState([]);
  const [numModality, setNumModality] = useState([]);
  const [numLevel, setNumLevel] = useState([]);
  // const [numFactor, setNumFactor] = useState([]);
  // const [numDimension, setNumDimension] = useState([]);

  const [columns, setColumns] = useState([
    {
      accessor: 'title',
      Header: 'Nombre'
    },
    {
      accessor: 'duration',
      Header: 'Calificación Promedio'
    }
  ]);
  const [allStudents, setallStudents] = useState([]);
  const [title, setTitle] = useState('');

  const { list, emodal, handleEModal } = useDefault();

  useEffectAsync(async () => {
    const TotalLeads = () => {
      list('dashboard_university_client/data_programs_university').then(
        data => {
          if (data && data.items) {
            const university = data.items;
            lmsStatistics[0].amount = university.programs;
            lmsStatistics[1].amount = university.pregrado;
            lmsStatistics[2].amount = university.posgrado;
            lmsStatistics[3].amount = university.course;
            lmsStatistics[4].amount = university.presencial;
            lmsStatistics[5].amount = university.virtual;
            lmsStatistics[6].amount = university.distancia;
            if (university.accredited) {
              lmsStatistics[7].title = 'Universidad Acreditada';
            } else {
              lmsStatistics[7].title = 'Universidad no acreditada';
            }
            setAccreditedPrograms(university.accreditedPrograms);
            setCareersDisappear(university.careers_disappear);
            setTop10(university.top10);
            setLevels(university.levels);
            setCities(university.cities);
            setModalitys(university.modalitys);
            setPrograms(university.programsP);
            setName(university.name);
          }
        }
      );
    };
    const getNPS = () => {
      list('dashboard_university_client/cal_prom_answers').then(data => {
        if (data) {
          setNumCities(data.cities);
          setNumUniversities(data.universities);
          setNumLevel(data.levels);
          setNumModality(data.modalitys);
          // setNumDimension(data.dimensions);
          // setNumFactor(data.factors);
        }
      });
    };
    TotalLeads();
    getNPS();
  }, []);
  if (
    accreditedPrograms === undefined ||
    careersDisappear === undefined ||
    programs === undefined ||
    numCities === undefined ||
    numUniversities === undefined ||
    numLevel === undefined ||
    numModality === undefined ||
    name === null
  )
    return <BackDrop param={' '} />;
  if (careersDisappear.desaparecer !== undefined && name !== null)
    return (
      <>
        <Alert.Heading>{name}1</Alert.Heading>
        <Row className="g-3 mb-3">
          <LmsStats data={lmsStatistics} />
        </Row>

        <Row className="g-3 mb-3">
          <Col>
            <ProjectStatistics
              projectsTable={cities}
              title="Precios según Ciudad"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
          <Col>
            <ProjectStatistics
              projectsTable={modalitys}
              title="Precios según Modalidad"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
          <Col>
            <ProjectStatistics
              projectsTable={levels}
              title="Precios según Nivel"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col>
            <Col xl={12}>
              <UnsolvedTickets
                title="Precios por Programa"
                data={programs}
                columns={columns3}
              />
            </Col>
            {/* <ProjectStatistics
              projectsTable={programs}
              title="Precios por Programa"
              setTitle={setTitle}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
            /> */}
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <Col xl={12}>
            <UnsolvedTickets
              title="Carreras a Desaparecer"
              data={careersDisappear.desaparecer}
              columns={columns3}
            />
          </Col>
          <Col xl={12}>
            <UnsolvedTickets
              title="Programas Top 10"
              data={top10.tops}
              columns={columns3}
            />
          </Col>
        </Row>
        <Row className="g-3 mb-3">
          <UnsolvedTickets
            title="Programas Acreditados"
            data={accreditedPrograms.accrediteds}
            columns={columns3}
          />
        </Row>
        {/* <Row className="g-3 mb-3">
          <Alert.Heading>Benchmark</Alert.Heading>
          <Col lg="4">
            <UniversityProjects
              data={numLevel}
              title={`Por Nivel Académico`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
          <Col lg="4">
            <UniversityProjects
              data={numModality}
              title={`Por Modalidad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
          <Col lg="4">
            <UniversityProjects
              data={numCities}
              title={`Por Ciudad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
          <Col lg="4">
            <UniversityProjects
              data={numUniversities}
              title={`Por Universidad`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
          <Col lg="4">
            <UniversityProjects
              data={numDimension}
              title={`Por Dimensión`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
          <Col lg="4">
            <UniversityProjects
              data={numFactor}
              title={`Por Factor`}
              icon={`user-graduate`}
              setColumns={setColumns}
              allStudents={allStudents}
              setallStudents={setallStudents}
              handleEModal={handleEModal}
              setTitle={setTitle}
              columns={columns}
            />
          </Col>
        </Row> */}
        <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        />
      </>
    );
};

export default DashboardUniversity;
