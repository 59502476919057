import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import React from 'react';
import PropTypes from 'prop-types';

const getOptions = (nameUniversity, valueUniversity, porcents) => ({
  yAxis: [
    {
      data: nameUniversity,
      axisLabel: {
        inside: true,
        color: getColor('gray-700'),
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'poppins'
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    {
      data: porcents,
      axisLabel: {
        inside: false,
        color: getColor('primary'),
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'poppins',
        borderRadius: 5,
        backgroundColor: getColor('soft-primary'),
        padding: [10, 0, 8, 9],
        width: 115
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    }
  ],
  xAxis: {
    type: 'value',
    min: 0,
    max: 250,
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    inverse: true,
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },

  series: [
    {
      type: 'bar',
      showBackground: true,
      barWidth: 30,
      label: {
        show: true,
        formatter: '{c} ',
        position: 'insideLeft'
      },
      backgroundStyle: {
        color: getColor('gray-200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor('primary'),
        borderRadius: 5
      },
      data: valueUniversity.map(value => ({
        value,
        label: {
          offset: value === 0 ? [-16, 0] : [0, 0]
        }
      }))
    }
  ],
  grid: { right: '60px', left: '0', bottom: '0', top: '0' }
});

const BarSubComponent = ({ data }) => {
  const { nameUniversity, valueUniversity, mayorUniv } = data;

  const porcents = valueUniversity.map(dat =>
    ((dat / mayorUniv.value) * 100).toFixed(1)
  );

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(nameUniversity, valueUniversity, porcents)}
      style={{ height: '11rem' }}
    />
  );
};

BarSubComponent.propTypes = {
  data: PropTypes.array
};

export default BarSubComponent;
