import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';
import Bar from 'components/dashboards/benchmark/Bar2';
// import UniversityProjects from 'components/dashboards/default/UniversityProjects';
// import { EModal } from 'components/templates/Modal';
// import TableModal from 'components/dashboards/futura/TableModal';

import CourseFiltersNPSAdmin from 'components/dashboards/e-learning/CourseFiltersNPSAdmin';

const NPS_Admin = () => {
  const { list } = useDefault();
  // const { emodal, handleEModal, list } = useDefault();
  const [universities, setUniversities] = useState([]);
  const [numDimension, setNumDimension] = useState();
  // const [dimension, setDimension] = useState([]);
  // const [level, setLevel] = useState([]);
  // const [modality, setModality] = useState([]);
  const [filter, setFilter] = useState(true);
  const [search, setSearch] = useState(false);
  // const [columns, setColumns] = useState([
  //   {
  //     accessor: 'title',
  //     Header: 'Nombre'
  //   },
  //   {
  //     accessor: 'duration',
  //     Header: 'Calificación Promedio'
  //   }
  // ]);
  // const [allStudents, setallStudents] = useState([]);
  // const [title, setTitle] = useState('');
  const [items, setItems] = useState();

  useEffect(() => {
    getNPS();
  }, []);

  const getNPS = () => {
    list('dashboard_nps/data_select').then(data => {
      data && setItems(data);
    });
    // list('dashboard_nps/cal_prom_answers_client').then(data => {
    //   if (data) {
    //     setDimension(data.dimensions);
    //     setLevel(data.levelsGrafic);
    //     setModality(data.modalitysGrafic);
    //     setNumDimension(data.dimensions);
    //   }
    // });
  };
  if (items === undefined || items === null) return <BackDrop param={' '} />;

  return (
    <>
      <Row className="g-3 mb-3">
        <Alert.Heading>
          {!filter && (
            <Row>
              {universities.title}: {universities.duration}%
            </Row>
          )}
        </Alert.Heading>
        <Col xl={3}>
          <CourseFiltersNPSAdmin
            data={items}
            setFilter={setFilter}
            setNumDimension={setNumDimension}
            // setLevel={setLevel}
            // setModality={setModality}
            setSearch={setSearch}
            // dimension={dimension}
            setUniversities={setUniversities}
          />
        </Col>
        <Col xl={9}>
          {search && <BackDrop param={' '} />}
          {!search && filter && (
            <Alert
              variant="info"
              style={{
                marginLeft: '30px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {' '}
              <Alert.Heading>
                Debe seleccionar una universidad, una modalidad y un nivel
                académico!!!
              </Alert.Heading>
            </Alert>
          )}
          {/* {!filter && (
            <Row>
              <Col lg="6">
                <TopLeads data={level} title={'Por Nivel Académico'} />
              </Col>
              <Col lg="">
                <TopLeads data={modality} title={'Por Modalidad'} />
              </Col>
            </Row>
          )} */}
          {!filter && (
            <Row style={{ marginTop: '10px' }}>
              {Array.isArray(numDimension) &&
                numDimension.map(item => (
                  <Col lg="6" key={item} style={{ marginTop: '10px' }}>
                    <Bar
                      data={{
                        bar: item?.bar,
                        dataDimen: item?.dataDimen
                      }}
                    />
                  </Col>
                ))}
              {/* {Array.isArray(numDimension) &&
                numDimension.map(item => (
                  <Col lg="4" key={item.id} style={{ marginTop: '10px' }}>
                    <UniversityProjects
                      data={item.factor} // Usar item en lugar de numDimension
                      title={`Dimensión: ${item.title} ${item.duration}%`}
                      icon="user-graduate"
                      setColumns={setColumns}
                      allStudents={allStudents}
                      setallStudents={setallStudents}
                      handleEModal={handleEModal}
                      setTitle={setTitle}
                      columns={columns}
                    />
                  </Col>
                ))} */}
              {Array.isArray(numDimension) && numDimension.length === 0 && (
                <Alert
                  variant="danger"
                  style={{
                    marginLeft: '30px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                  }}
                >
                  <Alert.Heading>
                    No existen calificaciones para mostrar!!!
                  </Alert.Heading>
                </Alert>
              )}
            </Row>
          )}
        </Col>
      </Row>
      {/* <EModal
        form_={
          <TableModal items={allStudents} title={title} columns={columns} />
        }
        title={''}
        modalSize={'lg'}
        modal={emodal}
        handleModal={handleEModal}
      /> */}
    </>
  );
};

export default NPS_Admin;
