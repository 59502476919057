import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { getColor } from 'helpers/utils';
import React from 'react';
import PropTypes from 'prop-types';

const getOptions = (nameUniversity, valueUniversity) => ({
  yAxis: [
    {
      data: nameUniversity,
      axisLabel: {
        inside: true,
        color: getColor('gray-700'),
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'poppins'
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    }
  ],
  xAxis: {
    type: 'value',
    min: 0,
    max: 250,
    axisLine: {
      show: false
    },
    splitLine: {
      show: false
    },
    inverse: true,
    axisTick: {
      show: false
    },
    axisLabel: {
      show: false
    }
  },

  series: [
    {
      type: 'bar',
      showBackground: true,
      barWidth: 30,
      label: {
        show: true,
        formatter: '{c} ',
        position: 'insideLeft'
      },
      backgroundStyle: {
        color: getColor('gray-200'),
        borderRadius: 5
      },
      itemStyle: {
        color: getColor('primary'),
        borderRadius: 5
      },
      data: valueUniversity.map(value => ({
        value,
        label: {
          offset: value === 0 ? [-16, 0] : [0, 0]
        }
      }))
    }
  ],
  grid: { right: '10px', left: '0', bottom: '5px', top: '0' }
});

const BarSubComponent = ({ data }) => {
  console.log(data);
  const { nameUniversity, valueUniversity } = data;
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(nameUniversity, valueUniversity)}
      style={{ height: '11rem' }}
    />
  );
};

BarSubComponent.propTypes = {
  data: PropTypes.array
};

export default BarSubComponent;
