import React, { useState, useEffect } from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { useDefault } from 'hooks';
import BackDrop from 'components/templates/BackDrop';
// import UniversityProjects from 'components/dashboards/default/UniversityProjects';
// import { EModal } from 'components/templates/Modal';
// import TableModal from 'components/dashboards/futura/TableModal';
import TopLeads from 'components/dashboards/default/TopLeads';
import CourseFiltersNPS from 'components/dashboards/e-learning/CourseFiltersNPS';
import Bar from 'components/dashboards/benchmark/Bar2';

const NPS = () => {
  const { list } = useDefault();
  // const { emodal, handleEModal, list } = useDefault();
  // const [numCities, setNumCities] = useState([]);
  const [numUniversities, setNumUniversities] = useState([]);
  const [numDimension, setNumDimension] = useState();
  const [dimension, setDimension] = useState([]);
  // const [numFactors, setNumFactors] = useState([]);
  // const [numModality, setNumModality] = useState([]);
  // const [numLevel, setNumLevel] = useState([]);
  const [level, setLevel] = useState([]);
  const [modality, setModality] = useState([]);
  const [filter, setFilter] = useState(true);
  const [search, setSearch] = useState(false);
  // const [columns, setColumns] = useState([
  //   {
  //     accessor: 'title',
  //     Header: 'Nombre'
  //   },
  //   {
  //     accessor: 'duration',
  //     Header: 'Calificación Promedio'
  //   }
  // ]);
  // const [allStudents, setallStudents] = useState([]);
  // const [title, setTitle] = useState('');
  const [items, setItems] = useState();

  useEffect(() => {
    getNPS();
  }, []);

  const getNPS = () => {
    list('dashboard_nps/data_select_NPS').then(data => {
      data && setItems(data);
    });
    list('dashboard_nps/cal_prom_answers_client').then(data => {
      if (data) {
        setNumUniversities(data.universities);
        setDimension(data.dimensions);
        // setNumModality(data.modalitys);
        setLevel(data.levelsGrafic);
        setModality(data.modalitysGrafic);
        setNumDimension(data.dimensions);
      }
    });
  };
  if (
    // numCities === undefined ||
    // Object.keys(numCities).length === 0 ||
    // numUniversities === undefined ||
    items === undefined ||
    items === null ||
    numDimension === undefined
    // Object.keys(numDimension).length === 0
    // numFactors === undefined ||
    // Object.keys(numFactors).length === 0 ||
    // numLevel === undefined ||
    // Object.keys(numLevel).length === 0 ||
    // numModality === undefined ||
    // Object.keys(numModality).length === 0
  )
    return <BackDrop param={' '} />;
  if (
    items !== undefined
    // numUniversities?.cities?.length > 0
  )
    // if (numCities?.length > 0 || numUniversities?.length > 0)
    return (
      <>
        <Row className="g-3 mb-3">
          <Alert.Heading>
            {numUniversities.title}: {numUniversities.duration}%
          </Alert.Heading>
          <Col xl={3}>
            <CourseFiltersNPS
              data={items}
              setFilter={setFilter}
              setNumDimension={setNumDimension}
              dimension={dimension}
              setSearch={setSearch}
            />
          </Col>
          <Col xl={9}>
            {search && <BackDrop param={' '} />}
            {!search && filter && (
              <Row>
                <Col lg="6">
                  <TopLeads data={level} title={'Por Nivel Académico'} />
                </Col>
                <Col lg="6">
                  <TopLeads data={modality} title={'Por Modalidad'} />
                </Col>
              </Row>
            )}
            {!filter && (
              <Row style={{ marginTop: '10px' }}>
                {Array.isArray(numDimension) &&
                  numDimension.map(item => (
                    <Col lg="6" key={item} style={{ marginTop: '10px' }}>
                      <Bar
                        data={{
                          bar: item?.bar,
                          dataDimen: item?.dataDimen
                        }}
                      />
                    </Col>
                  ))}

                {/* {Array.isArray(numDimension) &&
                numDimension.map(item => (
                  <Col lg="4" key={item.id} style={{ marginTop: '10px' }}>
                    <UniversityProjects
                      data={item.factor} // Usar item en lugar de numDimension
                      title={`Dimensión: ${item.title} ${item.duration}%`}
                      icon="user-graduate"
                      setColumns={setColumns}
                      allStudents={allStudents}
                      setallStudents={setallStudents}
                      handleEModal={handleEModal}
                      setTitle={setTitle}
                      columns={columns}
                    />
                  </Col>
                ))} */}

                {Array.isArray(numDimension) && numDimension.length === 0 && (
                  <Alert
                    variant="danger"
                    style={{
                      marginLeft: '30px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%'
                    }}
                  >
                    <Alert.Heading>
                      No existen calificaciones para mostrar!!!
                    </Alert.Heading>
                  </Alert>
                )}
              </Row>
            )}
          </Col>
        </Row>
        {/* <EModal
          form_={
            <TableModal items={allStudents} title={title} columns={columns} />
          }
          title={''}
          modalSize={'lg'}
          modal={emodal}
          handleModal={handleEModal}
        /> */}
      </>
    );
};

export default NPS;
