export const dashboardRoutes = {
  label: 'Dashboard General',
  labelDisable: true,
  children: [
    {
      name: 'Dashboard General',
      icon: 'chart-pie',
      access: 'dashboard',
      labelDisable: false,
      active: true,
      children: [
        {
          name: 'Default',
          to: '/dashboard',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'defaultDash'
        },
        {
          name: 'IES Colombia',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'iesDash',
          children: [
            {
              name: 'Pricing',
              to: '/dashboard/pricing',
              exact: true,
              active: true,
              labelDisable: false,
              access: 'iesPricing'
            },
            {
              name: 'IES',
              to: '/dashboard/ies-colombia',
              exact: true,
              active: true,
              labelDisable: false,
              access: 'iesDash'
            },
            {
              name: 'Acreditación',
              to: '/dashboard/accredited',
              exact: true,
              active: true,
              labelDisable: false,
              access: 'iesAccredited'
            },
            {
              name: 'Carreras',
              to: '/dashboard/programs',
              exact: true,
              active: true,
              labelDisable: false,
              access: 'iesPrograms'
            }
          ]
        },
        {
          name: 'Referidos',
          to: '/dashboard/referrals',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'referralsDash'
        },
        {
          name: 'Encuesta',
          to: '/dashboard/survey',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'surveyDash'
        },
        {
          name: 'NPS',
          to: '/dashboard/nps',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'NPSDash'
        },
        {
          name: 'NPS Futura',
          to: '/dashboard/nps_super',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'NPSSuper'
        },
        {
          name: 'Benchmark',
          to: '/dashboard/benchmark',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'BenchmarkDash'
        },
        {
          name: 'E-Learning',
          to: '/dashboard/e-learning',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'elearningDash'
        },
        {
          name: 'Mi Dashboard',
          to: '/dashboard/university',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'universityDash'
        },
        {
          name: 'Leads',
          to: '/dashboard/leads',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'LeadsDash'
        },
        {
          name: 'Mis Leads',
          to: '/dashboard/leads_client',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'leadsUniversityDash'
        },
        {
          name: 'Banco',
          to: '/dashboard/Banck',
          exact: true,
          active: true,
          labelDisable: false,
          access: 'BanckDash'
        }
      ]
    }
  ]
};

export const securityRoutes = {
  label: 'usuarios',
  children: [
    {
      name: 'Gestión de usuarios',
      icon: 'user',
      to: '/security',
      active: true,
      access: 'user'
    }
    // {
    //   name: 'Cambiar contraseña',
    //   icon: 'wrench',
    //   to: '/security/change-password/:!pass',
    //   active: true
    // }
  ]
};

export const cmsFutura = {
  label: 'CMS Futura',
  access: 'futuraCMS',
  children: [
    {
      name: 'Universidades',
      icon: 'table',
      active: true,
      access: 'universitiesCMS',
      children: [
        {
          name: 'Gestión de Universidades',
          to: '/cms/universities',
          exact: true,
          active: true,
          access: 'universitiesCMSF'
        },
        {
          name: 'Sectores',
          to: '/cms/sectors',
          exact: true,
          active: true,
          access: 'sectorsCMSF'
        },
        {
          name: 'Departamentos',
          to: '/cms/departments',
          exact: true,
          active: true,
          access: 'departmentsCMSF'
        },
        {
          name: 'Ciudades',
          to: '/cms/cities',
          exact: true,
          active: true,
          access: 'citiesCMSF'
        }
      ]
    },
    {
      name: 'Programas',
      icon: 'table',
      active: true,
      access: 'programsCMSF',
      children: [
        {
          name: 'Modalidades',
          to: '/cms/modalitys',
          exact: true,
          active: true,
          access: 'modalitysCMSF'
        },
        {
          name: 'Áreas del Conocimiento',
          to: '/cms/knowledge-areas',
          exact: true,
          active: true,
          access: 'knowledge_areaCMSF'
        },
        {
          name: 'Núcleos Básicos del Conocimiento',
          to: '/cms/basic-core',
          exact: true,
          active: true,
          access: 'basic_coreCMSF'
        },
        {
          name: 'Niveles de Formación',
          to: '/cms/levels-education',
          exact: true,
          active: true,
          access: 'levels_educationCMSF'
        },
        {
          name: 'Niveles Académicos',
          to: '/cms/levels',
          exact: true,
          active: true,
          access: 'levels_academicCMSF'
        },
        {
          name: 'Períodos de Duración',
          to: '/cms/periods',
          exact: true,
          active: true,
          access: 'periodsCMSF'
        }
      ]
    },
    {
      name: 'NPS',
      icon: 'table',
      active: true,
      access: 'npsCMS',
      children: [
        {
          name: 'Dimensión',
          to: '/cms/dimensions',
          exact: true,
          active: true,
          access: 'dimensionCMSF'
        }
      ]
    }
  ]
};

export const cmsRoutes = {
  label: 'CMS Clientes',
  children: [
    {
      name: 'Universidad',
      icon: 'globe',
      active: true,
      to: '/cms/university',
      access: 'universityCMSR'
    },
    {
      name: 'Programas',
      icon: 'file-alt',
      active: true,
      access: 'programs_CMS',
      children: [
        {
          name: 'Gestión de Programas',
          to: '/cms/programs',
          exact: true,
          active: true,
          access: 'programs_CMSR'
        }
      ]
    },
    {
      name: 'E-Learning',
      icon: 'book',
      active: true,
      access: 'course_CMS',
      children: [
        {
          name: 'Gestión de Cursos',
          to: '/cms/courses',
          exact: true,
          active: true,
          access: 'courseCMSR'
        },
        {
          name: 'Gestión de E-Learning',
          to: '/cms/marksCourses',
          exact: true,
          active: true,
          access: 'marksCoursesCMSF'
        }
      ]
    },
    // {
    //   name: 'BootCamps',
    //   icon: 'flag',
    //   active: true,
    //   access: 'bootcampsCMS',
    //   children: [
    //     {
    //       name: 'Gestión de BootCamps',
    //       to: '/cms/bootcamps',
    //       exact: true,
    //       active: true,
    //       access: 'bootcampsCMS'
    //     },
    //     {
    //       name: 'Cliente',
    //       to: '/cms/marksBootCamps',
    //       exact: true,
    //       active: true,
    //       access: 'marksCoursesCMS'
    //     }
    //   ]
    // },
    // {
    //   name: 'Mentorías',
    //   icon: 'comments',
    //   exact: true,
    //   active: true,
    //   children: [
    //     {
    //       name: 'Gestión de Mentorías',
    //       to: '/cms/mentorings',
    //       exact: true,
    //       active: true,
    //       access: 'mentoringCMS'
    //     },
    //     {
    //       name: 'Cliente',
    //       to: '/cms/marksMentoring',
    //       exact: true,
    //       active: true,
    //       access: 'marksMentoringCMS'
    //     }
    //   ]
    // },
    {
      name: 'Bancos',
      icon: 'chart-line',
      active: true,
      access: 'bankCMS',
      children: [
        {
          name: 'Gestión de Bancos',
          to: '/cms/bank',
          exact: true,
          active: true,
          access: 'bankCMSR'
        },
        {
          name: 'Rango',
          to: '/cms/banksranges',
          exact: true,
          active: true,
          access: 'rangeCMSR'
        },
        {
          name: 'Características',
          to: '/cms/banksfeatures',
          exact: true,
          active: true,
          access: 'banksfeaturesCMSR'
        },
        {
          name: 'Requisitos',
          to: '/cms/banksrequirements',
          exact: true,
          active: true,
          access: 'banksrequirementsCMSR'
        }
      ]
    },
    {
      name: 'Orientación',
      icon: 'info',
      to: '/cms/orientation',
      active: true,
      access: 'orientationCMSR'
    },
    {
      name: 'Usuarios Beca Futura',
      active: true,
      icon: 'graduation-cap',
      access: 'scolarshipCMS',
      children: [
        {
          name: 'Programas',
          to: '/cms/applications-program',
          active: true,
          access: 'scolarshipProgramCMS'
        },
        {
          name: 'E Learning',
          to: '/cms/applications-course',
          active: true,
          access: 'scolarshipCourseCMS'
        }
        // {
        //   name: 'Mentorías',
        //   to: '/cms/applications-mentoring',
        //   active: true,
        //   access: 'scolarshipMentoringCMS'
        // },
        // {
        //   name: 'BootCamps',
        //   to: '/cms/applications-bootcamp',
        //   active: true,
        //   access: 'scolarshipBootCampsCMS'
        // }
      ]
    }
  ]
};

export const referralsRoutes = {
  label: 'Referidos',
  children: [
    {
      name: 'Lista de Espera',
      to: '/referral/referral-list',
      icon: 'users',
      exact: true,
      active: true,
      access: 'referralList'
    }
  ]
};

export const surveyRoutes = {
  label: 'NPS',
  children: [
    {
      name: 'Listado',
      icon: 'list',
      to: '/survey/survey-list',
      active: true,
      access: 'surveyListNPS'
    },
    {
      name: 'Cuestionario',
      icon: 'question-circle',
      to: '/survey/survey-question',
      active: true,
      access: 'surveyQuestionNPS'
    },
    {
      name: 'Referidos',
      to: '/survey/survey-referral',
      icon: 'users',
      active: true
    }
  ]
};

export default [
  dashboardRoutes,
  cmsFutura,
  cmsRoutes,
  referralsRoutes,
  surveyRoutes,
  securityRoutes
];
