import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Form, Row } from 'react-bootstrap';
import LineSubComponent from './LineSubComponent';
import Flex from 'components/common/Flex';

const Line = ({ data, dimensionName }) => {
  const { nameUniversity } = data;
  // const data0 = {
  //   lastMonth: [50, 80, 60, 80, 65, 90, 130, 90, 30, 40, 30, 70],
  //   previousYear: [110, 30, 40, 50, 80, 70, 50, 40, 110, 90, 60, 60]
  // };

  const chartRef = useRef(null);

  const handleLegendToggle = name => {
    chartRef.current.getEchartsInstance().dispatchAction({
      type: 'legendToggleSelect',
      name
    });
  };

  const colorClasses = [
    'form-check-input-primary',
    'form-check-input-secondary',
    'form-check-input-success',
    'form-check-input-danger',
    'form-check-input-warning'
  ];

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center g-0">
          <Col xs="auto">
            <h6 className="mb-3">{`Factores Asociados a: ${dimensionName}`}</h6>
          </Col>
          <Col md="auto" className="order-1">
            <Flex className="flex-wrap gap-2 gap-md-3">
              {nameUniversity?.map((item, i) => {
                const colorClass = colorClasses[i % colorClasses.length];

                return (
                  <Form.Check
                    type="checkbox"
                    id={`${i}/`}
                    className="mb-0 d-flex"
                    key={i}
                  >
                    <Form.Check.Input
                      type="checkbox"
                      className={colorClass}
                      onClick={() => handleLegendToggle(item)}
                      defaultChecked
                    />
                    <Form.Check.Label className="ps-2 fs--2 text-600 mb-0">
                      {item}
                      {/* <span className="text-dark"> {average}</span> */}
                    </Form.Check.Label>
                  </Form.Check>
                );
              })}
            </Flex>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="pe-xxl-0">
        <LineSubComponent
          data={data}
          ref={chartRef}
          style={{ height: '18.625rem' }}
        />
      </Card.Body>
    </Card>
  );
};

Line.propTypes = {
  data: PropTypes.shape({
    dataUniv: PropTypes.object,
    nameFactor: PropTypes.array,
    nameUniversity: PropTypes.array
  }),
  dimensionName: PropTypes.string
};

export default Line;
